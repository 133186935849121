import { USER_LOCALSTORAGE_KEY } from '../const/localStorageConst';

export default (): any => {
  const {
    userDto: { roles, username, id },
  } = JSON.parse(localStorage.getItem(USER_LOCALSTORAGE_KEY) as string) || {
    userDto: { roles: [], username: '' },
  };

  const token = JSON.parse(localStorage.getItem(USER_LOCALSTORAGE_KEY) as string)?.jwt;

  return {
    token: token,
    roles,
    username,
    id,
  };
};
