import { ApolloLink, from, type NextLink, type Operation, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { Notifications } from '../../shared/const/Notifications';
import useErrorsHandler from '../../shared/hooks/useErrorsHandler';
import { notification } from 'antd';

const NotificationsProvider = () => {
  const client = useApolloClient();
  const errorHandler = useErrorsHandler();

  const linkHandler = (forward: NextLink, operation: Operation) => {
    return forward(operation).map((result) => {
      if (result?.data && Object.values(result.data).every((value: any) => !!value)) {
        const notify = Notifications[operation.operationName];
        if (notify) {
          notification.success({ message: notify });
        }
      }
      return result;
    });
  };

  useEffect(() => {
    const notificationsLink = new ApolloLink((operation, forward) =>
      linkHandler(forward, operation),
    );

    client.setLink(from([errorHandler, notificationsLink, client.link]));
  }, []);

  return null;
};
export default NotificationsProvider;
