import styles from './ProgresBar.module.scss';
import Typography from '../../../../shared/ui/Typography/Typography';
import clsx from 'clsx';

const ProgressBar = (props: any) => {
  const {
    completed,
    textInCentr = false,
    textInEnd = false,
    className,
    filColor = 'var(--primary)',
    bgColor = 'var(--gray)',
    borderRadius = 50,
    percentClassName,
  } = props;

  const containerStyles: any = {
    display: 'flex',
    width: '80%',
    backgroundColor: bgColor,
    borderRadius: borderRadius,
    justifyContent: 'flex-end',
  };

  const fillerStyles: any = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: `${completed}%`,
    backgroundColor: filColor,
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  const labelStyles: any = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
    font: 'var(--font-m)',
  };

  const wrapper = {
    width: '100%',
    font: 'var(--font-m)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const percentClsx = clsx(styles.percentText, percentClassName);

  return (
    <div style={wrapper}>
      <div style={containerStyles} className={className}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{textInCentr && completed + ' %'}</span>
        </div>
      </div>
      <Typography className={percentClsx}>{textInEnd && completed + '%'}</Typography>
    </div>
  );
};

export default ProgressBar;
