import { Doughnut } from 'react-chartjs-2';
import { AdvCampaignInfo } from '../../../../../__generated__/graphql';
import { keysStats, ageGroupsType } from '../../../../../shared/types/types';
import styles from './GeneralStat.module.scss';
import { statsBlock } from './config/statsBlocks';
import { ageGroups } from './config/ageBlock';
import ProgressBar from '../ProgresBar';
import { getSplittedNumber } from '../../../../../shared/helpers/getSplittedNumber';
import clsx from 'clsx';
import BlockButton from '../../../../../shared/ui/BlockButton/BlockButton';
import { FiDownload } from 'react-icons/fi';
import LineChart from './LineChart/LineChart';
import useDiagramData from '../../../model/useDiagramsData';
import { useState } from 'react';
import saveAs from 'file-saver';
import { downloadReport } from './serviceDownload/service-donwload';

const GeneralStat = ({ campaign }: { campaign: AdvCampaignInfo }) => {
  const { dataOnDiagramPercent, dataViewProgressOnDiagram, dataViewProgressAxes } =
    useDiagramData(campaign);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const createReport = async () => {
    setIsBtnLoading(true);
    const { data } = await downloadReport(campaign.id);
    saveAs(data);
    setIsBtnLoading(false);
  };

  if (!campaign.placements?.length) {
    return (
      <div className={styles.textNone}>
        <img src="/assets/logo/Logo_Black_Box.svg" />
        <span className={styles.textPrim}>Размещений пока нет</span>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.generalStats}>
        {Object.keys(statsBlock).map((el: any) => {
          return (
            <div key={el} className={styles.statsBlock}>
              <div className={styles.smallText}>{statsBlock[el as keysStats].title}</div>
              <div className={styles.primaryText}>
                {!el.includes('Cpv')
                  ? getSplittedNumber(campaign.generalStats[el as keysStats])
                  : getSplittedNumber(campaign.generalStats[el as keysStats].toFixed(1)) +
                    ' руб/просмотр '}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.statsAuditory}>
        <div className={styles.cardAndTitle}>
          <div className={styles.titleText}>Аудитория</div>
          <div className={styles.cardPeople}>
            <div className={styles.people}>
              <ul>
                <li className={styles.textMan}>
                  {Math.round(campaign.genderStats.percentMan)}% мужчин
                </li>
                <li className={styles.textWoman}>
                  {Math.round(campaign.genderStats.percentWoman)}% женщин
                </li>
              </ul>
            </div>
            <div
              className={clsx(styles.diagram, {
                [styles.noneDiagram]:
                  dataOnDiagramPercent.datasets[0].data[0] === 0 &&
                  dataOnDiagramPercent.datasets[0].data[1] === 0,
              })}
            >
              {dataOnDiagramPercent.datasets[0].data[0] !== 0 &&
              dataOnDiagramPercent.datasets[0].data[1] !== 0 ? (
                <Doughnut
                  data={dataOnDiagramPercent}
                  options={{
                    cutout: '70%',
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              ) : (
                <div className={styles.smallText}>Аудитория не указана</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.cardAndTitle}>
          <div className={styles.titleText}>Возраст аудитории</div>
          <div className={styles.cardAge}>
            <div className={styles.list}>
              {Object.keys(ageGroups).map((el: any) => (
                <div key={el} className={styles.listElement}>
                  <div className={styles.textMan}>{ageGroups[el as ageGroupsType].desc}</div>
                  <div className={styles.wrapperBar}>
                    <ProgressBar
                      completed={Math.round(campaign.ageStats[el as ageGroupsType])}
                      textInEnd
                      className={styles.progressBar}
                      percentClassName={styles.progressBarPercents}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.statView}>
        <div className={styles.statHeader}>
          <div className={styles.titleText}>Прогресс просмотров</div>
        </div>
        <div className={styles.cardView}>
          <div className={clsx(styles.smallText, styles.diagramLegend)}>Прирост просмотров</div>
          <LineChart
            dataViewProgressOnDiagram={dataViewProgressOnDiagram}
            dataViewProgressAxes={dataViewProgressAxes}
          />
        </div>
      </div>
      <BlockButton
        disabled={isBtnLoading}
        title="Скачать отчет"
        Icon={FiDownload}
        onClick={createReport}
        size={70}
        isLoading={isBtnLoading}
      />
    </div>
  );
};

export default GeneralStat;
