import clsx from 'clsx';
import { getSplittedNumber } from '../../../../../../shared/helpers/getSplittedNumber';
import Eye from '../../../../../../shared/ui/Icons/Eye/Eye';
import IconChanel from '../../../../../../shared/ui/Icons/IconChanel/IconChanel';
import styles from '../Placement.module.scss';
import ProgressBar from '../../../../../../shared/ui/ProgressBar/ProgresBar';
import { ColumnsType } from 'antd/lib/table';
import InputTime from '../componets/InputTime';
import { SlotPosition } from '../../../../../../__generated__/graphql';

const useTablePlacements = (
  placements: any,
  campId: string,
): { columns: ColumnsType; dataPlace: any } => {
  const dataPlace = placements.map((el: any) => {
    return {
      claimId: el.id,
      channelName: el.channel.name,
      video: {
        link: el.video.ytInfo.preview.fullLink,
        name: el.video.ytInfo.videoName,
        videoLink: el.video.videoUrlLink,
      },
      viewCount: el.viewsData.videoTotalViews,
      viewCountProgSlot: {
        left: el.viewsData?.heatseekerData?.videoCampaignViewsCountSlot,
        target: el.expectedViewsCount,
        percent: el.viewsData?.heatseekerData?.videoCampaignViewsPercentsSlot,
      },
      viewCountProgDetailed: {
        left: el.viewsData?.heatseekerData?.videoCampaignViewsCountDetailed,
        target: el.expectedViewsCount,
        percent: el.viewsData?.heatseekerData?.videoCampaignViewsPercentsDetailed,
      },
      formattedScreenshot: el.formattedScreenshot,
      screenshotTimeMarkInSeconds: el.screenshotTimeMarkInSeconds,
      slot: el.slot,
    };
  });

  const columns = [
    {
      key: '1',
      name: 'channelName',
      dataIndex: 'channelName',
      title: 'Канал',
      render: (value: any) => (
        <div className={styles.iconAndName}>
          <IconChanel className={styles.icon} />
          <span className={styles.smallText}> {value}</span>
        </div>
      ),
    },
    {
      key: '2',
      name: 'video',
      dataIndex: 'video',
      title: 'Ролик',
      render: (value: any) => (
        // eslint-disable-next-line
        <a className={styles.videoWrapper} href={value.videoLink} target="_blank">
          <img src={value.link} className={styles.videoAdv} alt="videoAdv" />
          <span className={styles.smallText}>{value.name}</span>
        </a>
      ),
      width: 400,
    },
    {
      key: '3',
      name: 'viewCount',
      dataIndex: 'viewCount',
      title: 'Просмотры ролика канала',
      render: (value: any) => <span className={styles.numbers}>{getSplittedNumber(value)}</span>,
    },
    {
      key: '3',
      name: 'viewCountProgSlot',
      dataIndex: 'viewCountProgSlot',
      title: 'Просмотры интеграции (к прогнозируемым)',
      render: (value: any) => {
        if (value.left && value.target && value.percent) {
          return (
            <div className={styles.wrapper}>
              <span className={clsx(styles.smallText, styles.textWithEye)}>
                <Eye className={styles.eye} />
                {getSplittedNumber(value.left)} из {getSplittedNumber(value.target)}
              </span>
              <ProgressBar percent={value.percent} />
            </div>
          );
        } else {
          return <span>Некорректный видеоролик для расчета статистики</span>;
        }
      },
    },
    {
      key: '4',
      name: 'formattedScreenshot',
      dataIndex: 'formattedScreenshot',
      title: 'Скриншот рекламы',
      render: (value: any) => {
        if (value?.fullLink) {
          return (
            <div className={styles.videoWrapper}>
              <img src={value.fullLink} className={styles.videoAdv} alt="screenShot" />
            </div>
          );
        }
        return <span className={styles.smallText}>Скриншота пока нет</span>;
      },
      width: 'auto',
    },
    {
      key: '5',
      name: 'screenshotTimeMarkInSeconds',
      dataIndex: 'screenshotTimeMarkInSeconds',
      title: 'Временная метка',
      render: (value: any, record: any) => {
        if (record.slot === SlotPosition.Preroll) {
          return (
            <span className={styles.smallText}>
              В слоте с типом Преролл скриншот берется с первой секнуды ролика
            </span>
          );
        }
        return <InputTime value={value} claimId={record.claimId} campId={campId} />;
      },
      width: 'auto',
    },
  ];

  return { columns, dataPlace };
};

export default useTablePlacements;
