import React, { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './ItemLink.module.scss';
import clsx from 'clsx';

interface AppLinkProps {
  route: string;
  title: string;
  icon: ReactNode;
  onClick: () => void;
}

export const ItemLink: FC<AppLinkProps> = (props) => {
  const location = useLocation();
  const { route, title, icon, onClick } = props;

  const isActive = location.pathname.includes(route);

  const itemLinkClassName = clsx(styles.item, { [styles.item_active]: isActive });

  return (
    <Link to={route} className={itemLinkClassName} onClick={onClick}>
      <span className={styles.iconAndText}>
        {icon}
        {title}
      </span>
    </Link>
  );
};
