export const statsBlock = {
  viewCountActual: {
    title: 'Просмотры',
  },
  kpi: {
    title: 'KPI',
  },
  buyCpv: {
    title: 'CPV плановый',
  },
  factCpv: {
    title: 'CPV фактический',
  },
};
