import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  routeConfig,
  routePrivateConfig,
} from '../../../../shared/config/routerConfig/routerConfig';
import { Loader } from '../../../../shared/ui/Loader/Loader';
import PrivateRoute from './PrivateRoute';
import { useAuth } from '../../../../shared/hooks/useAuth';

const AppRouter = () => {
  const { userRoles } = useAuth();

  return (
    <Routes>
      {Object.values(routeConfig).map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={<Suspense fallback={<Loader />}>{element}</Suspense>}
        />
      ))}
      <Route element={<PrivateRoute />}>
        {routePrivateConfig
          .filter(({ accesses }) => accesses.some((item) => userRoles?.includes(item)))
          .map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<Suspense fallback={<Loader />}>{element}</Suspense>}
            />
          ))}
      </Route>
    </Routes>
  );
};

export default AppRouter;
