import { TableComponent } from '../../../../../shared/ui/Table/Table';
import styles from './Placement.module.scss';
import useTablePlacements from './module/useTablePlacements';
import BlockButton from '../../../../../shared/ui/BlockButton/BlockButton';
import { FiDownload } from 'react-icons/fi';
import { downloadZipScreens } from '../GeneralStat/serviceDownload/service-donwload';
import saveAs from 'file-saver';
import { useState } from 'react';

const Placements = ({ placements, id }: { placements?: any; id: string }): JSX.Element => {
  const { columns, dataPlace } = useTablePlacements(placements, id);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createArchWithScreens = async () => {
    setIsLoading(true);
    const { data } = await downloadZipScreens(id);
    saveAs(data);
    setIsLoading(false);
  };

  if (!placements?.length) {
    return (
      <div className={styles.textNone}>
        <img src="/assets/logo/Logo_Black_Box.svg" />
        <span className={styles.textPrim}>Размещений пока нет</span>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <TableComponent
          columns={columns}
          bordered
          rowId="placement"
          pagination={false}
          showSaveDropDown
          data={dataPlace}
          showClearFilters={false}
          scroll={{ x: '1000px', y: '500px' }}
        />
      </div>
      <BlockButton
        disabled={isLoading}
        title="Скачать скриншоты"
        Icon={FiDownload}
        onClick={createArchWithScreens}
        size={70}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Placements;
