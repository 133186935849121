import React, { createContext, useState } from 'react';
import getLoginData from '../../../shared/helpers/getLoginData';

export interface AuthContextType {
  isAuthenticated: boolean;
  setAuth: (auth: boolean) => void;
  userRoles: string[] | null;
  setRoles: (role: string[] | null) => void;
  id: string;
  token: string;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setAuth: () => {},
  userRoles: null,
  setRoles: () => {},
  id: '',
  token: '',
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { token, roles, id } = getLoginData();
  const [isAuthenticated, setAuth] = useState<boolean>(!!token);
  const [userRoles, setRoles] = useState<string[] | null>(roles);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuth,
        userRoles,
        setRoles,
        id,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
