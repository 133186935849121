import clsx from 'clsx';
import CheckCircle from '../Icons/CheckCircle/CheckCircle';
import RocketLaunch from '../Icons/RocketLaunch/RocketLaunch';
import SpinnerGap from '../Icons/SpinnerGap/SpinnerGap';
import styles from './StatusBtn.module.scss';
import { ReactElement } from 'react';
import { CompanyStatus } from '../../types/types';

const allStatus: Record<CompanyStatus, { icon: ReactElement; text: string }> = {
  LAUNCHED: {
    icon: <RocketLaunch className={styles.icon} />,
    text: 'Запущена',
  },
  NOT_LAUNCHED: {
    icon: <SpinnerGap className={styles.icon} />,
    text: 'Создана',
  },
  NEEDS_APPROVE: {
    icon: <SpinnerGap className={styles.icon} />,
    text: 'На одобрении',
  },
  APPROVED: {
    icon: <SpinnerGap className={styles.icon} />,
    text: 'Не запущена',
  },
  ENDED: {
    icon: <CheckCircle className={styles.icon} />,
    text: 'Завершена',
  },
  PART: {
    icon: <RocketLaunch className={styles.icon} />,
    text: 'Частично оплачено',
  },
  NONE: {
    icon: <SpinnerGap className={styles.icon} />,
    text: 'Не оплачено',
  },
  FULL: {
    icon: <CheckCircle className={styles.icon} />,
    text: 'Оплачено',
  },
  AVAILABLE: {
    icon: <RocketLaunch className={styles.icon} />,
    text: 'Доступен',
  },
  NOT_AVAILABLE: {
    icon: <SpinnerGap className={styles.icon} />,
    text: 'Не доступен',
  },
};

const StatusBtn = ({ status, className }: { status: CompanyStatus; className?: any }) => {
  const statusClass = clsx(styles.root, styles[status], className);

  return (
    <div className={statusClass}>
      {allStatus[status].text}
      {allStatus[status].icon}
    </div>
  );
};

export default StatusBtn;
