import { useAuth } from './useAuth';
import { USER_LOCALSTORAGE_KEY } from '../const/localStorageConst';
import { useNavigate } from 'react-router-dom';

export const useOnLogOutClick = (): { onLogOutClick: (state?: string) => void } => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const onLogOutClick = (state: string | undefined): void => {
    localStorage.removeItem(USER_LOCALSTORAGE_KEY);
    setAuth(false);
    navigate('/login', { replace: true, state: state || '' });
  };
  return { onLogOutClick };
};
