import { TabsTypes, IUserConfig } from '../../types/types';
import getUserConfig from './getUserConfig';

export default (userConfig: IUserConfig): void => {
  const lsConfig = getUserConfig();
  let resultConfig = userConfig;

  if (lsConfig) {
    resultConfig = {
      ...lsConfig,
      ...userConfig,
      lastOpenTabs: {
        ...lsConfig?.lastOpenTabs,
        ...userConfig?.lastOpenTabs,
      },
    };
  }

  if (!Object.keys(userConfig).length && !lsConfig) {
    resultConfig = {
      lastOpenTabs: {
        activityControl: TabsTypes.Online,
        videoControl: TabsTypes.Online,
        doorsControl: TabsTypes.Online,
        steamControl: TabsTypes.Online,
        reports: TabsTypes.Activity,
        hardware: TabsTypes.Badges,
        settings: TabsTypes.Activity,
      },
    };
  }

  localStorage.setItem('', JSON.stringify(resultConfig));
};
