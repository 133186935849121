import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Controller, useForm } from 'react-hook-form';
import TimePicker from '../../../../../../shared/ui/TimePicker/TimePicker';
import Button from '../../../../../../shared/ui/Button/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './InputTime.module.scss';
import {
  FindAdvCampaignByIdFullStatsDocument,
  useUpdateClaimScreenshotTimeMarkMutation,
} from '../../../../../../__generated__/graphql';
import { useState } from 'react';
import { Loader } from '../../../../../../shared/ui/Loader/Loader';
import useRoles from '../../../../../../shared/hooks/useRoles';
import Typography from '../../../../../../shared/ui/Typography/Typography';
dayjs.extend(duration);
const InputTime: React.FC<{
  value?: string;
  claimId: string;
  campId: string;
}> = ({ value, claimId, campId }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { isManager, isAdmin } = useRoles();
  const [updateTime] = useUpdateClaimScreenshotTimeMarkMutation();
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(
      yup.object({
        time: yup.number().required('Время обязательно'),
      }),
    ),
    defaultValues: {
      time: Number(value) || 0,
    },
  });

  const onSubmit = async (formValues: any) => {
    setLoading(true);
    await updateTime({
      variables: {
        input: {
          claimId: claimId,
          screenshotTimeMarkInSeconds: formValues.time,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: FindAdvCampaignByIdFullStatsDocument, variables: { id: campId } }],
    });
    setLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isManager() || isAdmin()) {
    return (
      <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="time"
          render={({ field, fieldState: { error } }) => {
            return (
              <TimePicker
                value={field.value ? dayjs.unix(field.value) : null}
                onSelect={(e: any) => {
                  setValue('time', dayjs.duration({ minutes: e.$m, seconds: e.$s }).asSeconds());
                }}
                format="mm:ss"
                placeholder="Выберите время на видео"
                error={error}
              />
            );
          }}
        />
        <Button type="submit">Сохранить</Button>
      </form>
    );
  }

  return <Typography>{dayjs.unix(Number(value)).format('mm:ss') || ''} </Typography>;
};

export default InputTime;
