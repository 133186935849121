import './styles/Modal.scss';
import { Modal } from 'antd';
import { ModalProps } from '../../types/types';

export const ModalComponent = ({
  isModalOpen,
  closeIcon = true,
  className,
  children,
  onClose,
  destroyOnClose = false,
}: ModalProps) => {
  return (
    <Modal
      open={isModalOpen}
      onCancel={onClose}
      className={className}
      footer={null}
      destroyOnClose={destroyOnClose}
      closeIcon={closeIcon}
    >
      {children}
    </Modal>
  );
};
