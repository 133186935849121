import { useEffect } from 'react';
import { Chart, type ChartItem, type ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ru } from 'date-fns/locale';

const chartOptions = (dataViewProgressOnDiagram: any): ChartOptions<'line'> => {
  return {
    responsive: true,

    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        bottom: 20,
      },
    },
    scales: {
      x: {
        // @ts-ignore
        type: 'time',
        time: {
          tooltipFormat: 'dd MMM yyyy |H:mm|',
          displayFormats: {
            hour: 'dd MMM |H:mm|',
          },
          unit: 'hour',
        },
        ticks: {
          stepSize: dataViewProgressOnDiagram.labels.length >= 8 ? 8 : 1,
        },
        adapters: {
          date: {
            locale: ru,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          drawTicks: false,
          // @ts-ignore
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      zoom: {
        zoom: {
          drag: {
            enabled: false,
          },
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false,
          },
          mode: 'x',
        },
        pan: {
          enabled: false,
        },
      },
    },
    interaction: {
      intersect: false,
    },
  };
};

const chartAxesOptions: ChartOptions = {
  responsive: true,

  maintainAspectRatio: false,
  layout: {
    padding: {
      bottom: 49.5,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        drawTicks: false,
      },
    },
    y: {
      beginAtZero: true,
      afterFit: (ctx: any) => {
        ctx.width = 100;
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
    zoom: {
      zoom: {
        drag: {
          enabled: false,
        },
        wheel: {
          enabled: false,
        },
        pinch: {
          enabled: false,
        },
      },
      pan: {
        enabled: false,
      },
    },
  },
  interaction: {
    intersect: false,
  },
};

const LineChart = ({
  dataViewProgressOnDiagram,
  dataViewProgressAxes,
}: {
  dataViewProgressOnDiagram: any;
  dataViewProgressAxes: any;
}) => {
  useEffect(() => {
    const ctxAxes = (document.getElementById('chartAxes') as HTMLCanvasElement)?.getContext('2d');
    const chartAxes = new Chart(ctxAxes as ChartItem, {
      type: 'line',
      data: dataViewProgressAxes,
      options: chartAxesOptions,
    });

    const ctx = (document.getElementById('chart') as HTMLCanvasElement)?.getContext('2d');
    const chart = new Chart(ctx as ChartItem, {
      type: 'line',
      data: dataViewProgressOnDiagram,
      options: chartOptions(dataViewProgressOnDiagram),
    });
    const resizeChart = () => {
      chart.resize();
      chartAxes.resize();
    };

    window.addEventListener('afterprint', resizeChart);

    return () => {
      window.removeEventListener('afterprint', resizeChart);
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '55vh', display: 'flex' }}>
      <div style={{ width: 100 }}>
        <canvas id="chartAxes"></canvas>
      </div>
      <div style={{ maxWidth: '95vw', overflowX: 'scroll' }}>
        <div style={{ width: 'calc(1920px - 50px)', height: '55vh' }}>
          <canvas id="chart"></canvas>
        </div>
      </div>
    </div>
  );
};
export default LineChart;
