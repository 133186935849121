import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { AppHeader } from '../../../Header';
import styles from './AppLayout.module.scss';
import Footer from '../../../Footer/ui/Footer';
import AppVersion from '../../../AppVersion/ui/AppVersion';

interface AppLayoutProps {
  readonly children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <Layout className={styles.mainLayout}>
      <AppHeader />
      <AppVersion />
      <Layout className={styles.layout}>
        <Content className={styles.content}>
          {children}
          <Footer />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
