const RocketLaunch = (props: any) => {
  const { size, color = 'var(--primary)', className } = props;
  return (
    <svg
      width={size}
      height={size}
      color={color}
      viewBox="0 0 32 32"
      className={className}
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_74_2567)">
        <path
          d="M12 23C12 23 11 27 5 27C5 21 9 20 9 20"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8888 14.1112C26.8888 11.1112 27.0763 7.54244 26.9825 5.95119C26.9669 5.70867 26.8635 5.48012 26.6917 5.30827C26.5198 5.13643 26.2913 5.03304 26.0487 5.01744C24.4575 4.92369 20.8913 5.10869 17.8888 8.11119L10 15.9999L16 21.9999L23.8888 14.1112Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0003 9H9.29407C9.02921 9.00012 8.77521 9.10532 8.58782 9.2925L4.29407 13.5863C4.16298 13.7176 4.07103 13.8829 4.02853 14.0636C3.98602 14.2442 3.99464 14.4332 4.05341 14.6092C4.11218 14.7853 4.21878 14.9415 4.36129 15.0604C4.5038 15.1793 4.67659 15.2562 4.86032 15.2825L10.0003 16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 15V22.7062C22.9999 22.9711 22.8947 23.2251 22.7075 23.4125L18.4137 27.7062C18.2824 27.8373 18.1171 27.9293 17.9364 27.9718C17.7558 28.0143 17.5668 28.0057 17.3908 27.9469C17.2147 27.8881 17.0585 27.7815 16.9396 27.639C16.8207 27.4965 16.7438 27.3237 16.7175 27.14L16 22"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_2567">
          <rect width={32} height={32} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RocketLaunch;
