import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import ConfigProvider from './app/providers/ConfigProvider/ConfigProvider';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './app/providers/AuthProvider/AuthProvider';
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root') as HTMLElement,
);
