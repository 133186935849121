import { onError } from '@apollo/client/link/error';
import { notification } from 'antd';
import { useOnLogOutClick } from './useOnLogOutClick';

const useErrorHandlerLink = () => {
  const { onLogOutClick } = useOnLogOutClick();
  const errorHandler = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      notification.error({ message: 'Ошибка соединения', type: 'error' });
    }

    if (
      graphQLErrors?.some((err) => err.extensions?.classification === 'UNAUTHORIZED') &&
      onLogOutClick
    ) {
      onLogOutClick();
      return;
    }

    graphQLErrors?.forEach((err) => {
      if (
        err.extensions?.classification !== 'ValidationError' &&
        err.extensions?.classification !== 'INTERNAL_ERROR' &&
        err.extensions?.classification !== 'FORBIDDEN'
      ) {
        notification.error({ message: err.message, type: 'error' });
        return;
      }
      if (err.extensions?.classification === 'FORBIDDEN') {
        notification.error({ message: err.message || 'Отсутствуют права доступа', type: 'error' });
      }
      if (err.extensions?.classification === 'INTERNAL_ERROR') {
        notification.error({ message: 'Внутренняя ошибка сервера', type: 'error' });
      }
    });
  });
  return errorHandler;
};
export default useErrorHandlerLink;
