import { AdvCampaignInfo } from '../../../__generated__/graphql';
import { ITabItem } from '../../../shared/ui/Tabs/Tabs';
import Typography from '../../../shared/ui/Typography/Typography';
import styles from '../ui/StatsComapny.module.scss';
import GeneralStat from '../ui/componets/GeneralStat/GeneralStat';
import Placements from '../ui/componets/Placement/Placement';

const useTabControl = (campaign: AdvCampaignInfo): { items: ITabItem[] } => {
  const items: ITabItem[] = [
    {
      key: '1',
      label: ' Статистика',
      children: <GeneralStat campaign={campaign} />,
    },
    {
      key: '2',
      label: 'Размещения',
      children: <Placements placements={campaign?.placements} id={campaign?.id} />,
    },
  ];

  return { items };
};

export default useTabControl;
