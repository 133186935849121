const FolderSimpleStar = (props: any) => {
  const { size, color = 'var(--primary)', className } = props;
  return (
    <svg
      width={size}
      height={size}
      color={color}
      fill="transparent"
      className={className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_74_2571)">
        <path
          d="M15 26H5C4.73478 26 4.48043 25.8946 4.29289 25.7071C4.10536 25.5196 4 25.2652 4 25V8C4 7.73478 4.10536 7.48043 4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11.6663C11.8826 7 12.0932 7.07018 12.2662 7.2L15.7338 9.8C15.9068 9.92982 16.1174 10 16.3337 10H27C27.2652 10 27.5196 10.1054 27.7071 10.2929C27.8946 10.4804 28 10.7348 28 11V15"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 24.8175L26.7087 27L25.7 22.9263L29 20.2013L24.6688 19.8675L23 16L21.3312 19.8675L17 20.2013L20.3 22.9263L19.2913 27L23 24.8175Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_2571">
          <rect width={32} height={32} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FolderSimpleStar;
