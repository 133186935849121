export const ageGroups = {
  ageGroup1: {
    desc: '13-17 лет',
  },
  ageGroup2: {
    desc: '18-24 лет',
  },
  ageGroup3: {
    desc: '25–34 лет',
  },
  ageGroup4: {
    desc: '35–44 лет',
  },
  ageGroup5: {
    desc: '45–54 лет',
  },
  ageGroup6: {
    desc: '55–64 лет',
  },
  ageGroup7: {
    desc: '65+ лет',
  },
};
