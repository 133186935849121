const SignOut = (props: any) => {
  const { size, color = 'var(--primary)', className } = props;
  return (
    <svg
      width={size}
      height={size}
      color={color}
      viewBox="0 0 32 32"
      className={className}
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_74_2562)">
        <path
          d="M13 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V26C5 26.2652 5.10536 26.5196 5.29289 26.7071C5.48043 26.8946 5.73478 27 6 27H13"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 16H27"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 11L27 16L22 21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_2562">
          <rect width={32} height={32} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignOut;
