const Notifications: Record<string, string> = {
  addAdvCampaign: 'Кампания успешно добавлена',
  updateAdvCampaign: 'Кампания успешно обновлена',
  deleteAdvCampaign: 'Кампания успешно удалена',
  addCampaignVideo: 'Видео успешно добавлено к кампании',
  addChannel: 'Канал успешно добавлен',
  updateChannel: 'Канал успешно обновлен',
  deleteChannel: 'Канал успешно удален',
  addClaims: 'Заявка успешно создана',
  acceptClaim: 'Заявка успешно принята',
  approveClaim: 'Заявка успешно одобрена',
  addVideoToClaim: 'Видео успешно добавлено к заявке',
  addClient: 'Клиент успешно создан',
  updateClient: 'Клиент успешно обновлен',
  deleteClientById: 'Клиент успешно удален',
  updateUserPassword: 'Пароль успешно изменен',
  addClientCampaignRequest: 'Заявка успешно отправлена',
};

export { Notifications };
