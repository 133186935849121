const IconChanel = (props: any) => {
  const { size, color = 'var(--red)', className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      color={color}
      className={className}
      viewBox="0 0 60 61"
      fill="none"
    >
      <circle
        cx="30"
        cy="30.5"
        r="30"
        fill="#FF0000"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 13.5L30.441 13.5918L23.1024 30.4676L26.6726 30.4366L22.8702 38.8986L27.1053 38.8987L22 53.5L39.7211 25.6115L33.3244 25.6671L41 13.5Z"
        fill="white"
      />
    </svg>
  );
};

export default IconChanel;
