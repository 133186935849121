import { Navigate, type RouteProps } from 'react-router-dom';
import { Channels } from '../../../pages/Channels';
import { NotFoundPage } from '../../../pages/NotFoundPage';
import { LoginPage } from '../../../pages/LoginPage';
import { Loader } from '../../ui/Loader/Loader';
import { Customers } from '../../../pages/Customers';
import { Company } from '../../../pages/Company';
import { Platform } from '../../../pages/Platform';
import { AddChannel } from '../../../pages/AddChannel';
import { ApproveChanel } from '../../../pages/ApproveChanels';
import { AddCompanyToClient } from '../../../pages/AddCompanyToClient';
import StatComapany from '../../../pages/StatCompany/ui/StatComapany';
import { Roles } from '../../../__generated__/graphql';

export enum AppRoutes {
  LOGIN = 'login',
  NOT_FOUND = 'not_found',
  REDIRECT = 'redirect',
  REDIRECT_TO_LOG = 'redirectToLog',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.LOGIN]: '/login',
  [AppRoutes.NOT_FOUND]: '*',
  [AppRoutes.REDIRECT]: '/',
  [AppRoutes.REDIRECT_TO_LOG]: '/client/campaign-request/:id',
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.LOGIN]: {
    path: RoutePath.login,
    element: <LoginPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
  [AppRoutes.REDIRECT]: {
    path: RoutePath.redirect,
    element: <Navigate to="/login" replace />,
  },
  [AppRoutes.REDIRECT_TO_LOG]: {
    path: RoutePath.redirectToLog,
    element: <AddCompanyToClient />,
  },
};

export enum AppPrivateRoutes {
  CHANNELS = 'Channels',
  CUSTOMERS = 'Customers',
  COMPANY = 'Company',
  ADD_COMPANY_TO_CLIENT = 'AddCompanyToClient',
  LOADER = 'Loader',
  COMPANY_INFO_ID = 'CompanyInfoId',
  PLATFORM = 'Platform',
  ADD_CHANNEL = 'AddChannel',
  APPROVE_CHANNEL = 'ApproveChannel',
  CAMPAIGN_REQUEST = 'CampaignRequest',
}

export const RoutePrivatePath: Record<AppPrivateRoutes, string> = {
  [AppPrivateRoutes.CHANNELS]: '/channels',
  [AppPrivateRoutes.ADD_CHANNEL]: '/add-channel',
  [AppPrivateRoutes.CUSTOMERS]: '/customers',
  [AppPrivateRoutes.COMPANY]: '/company',
  [AppPrivateRoutes.ADD_COMPANY_TO_CLIENT]: '/client-add-company',
  [AppPrivateRoutes.LOADER]: '/',
  [AppPrivateRoutes.PLATFORM]: '/platform',
  [AppPrivateRoutes.APPROVE_CHANNEL]: '/approve-channel',
  [AppPrivateRoutes.COMPANY_INFO_ID]: '/company-info/:id',
  [AppPrivateRoutes.CAMPAIGN_REQUEST]: '/client/campaign-request/:id',
};

export const routePrivateConfig: (RouteProps & { accesses: string[] })[] = [
  {
    path: RoutePrivatePath.Channels,
    element: <Channels />,
    accesses: [Roles.AdminUser, Roles.ManagerUser],
  },
  {
    path: RoutePrivatePath.AddChannel,
    element: <AddChannel />,
    accesses: [Roles.AdminUser, Roles.ManagerUser],
  },
  {
    path: RoutePrivatePath.Customers,
    element: <Customers />,
    accesses: [Roles.AdminUser, Roles.ManagerUser],
  },
  {
    path: RoutePrivatePath.ApproveChannel,
    element: <ApproveChanel />,
    accesses: [Roles.AdminUser, Roles.ManagerUser],
  },
  {
    path: RoutePrivatePath.Company,
    element: <Company />,
    accesses: [Roles.AdminUser, Roles.ManagerUser, Roles.ClientUser],
  },
  {
    path: RoutePrivatePath.AddCompanyToClient,
    element: <AddCompanyToClient />,
    accesses: [Roles.ClientUser],
  },
  {
    path: RoutePrivatePath.CampaignRequest,
    element: <AddCompanyToClient />,
    accesses: [Roles.ManagerUser, Roles.AdminUser],
  },
  {
    path: RoutePrivatePath.Loader,
    element: <Loader />,
    accesses: [Roles.AdminUser, Roles.ManagerUser],
  },
  {
    path: RoutePrivatePath.CompanyInfoId,
    element: <StatComapany />,
    accesses: [Roles.AdminUser, Roles.ManagerUser, Roles.ClientUser],
  },
  {
    path: RoutePrivatePath.Platform,
    element: <Platform />,
    accesses: [Roles.ChannelUser],
  },
];
