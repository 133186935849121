import { type FC } from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';
import { ButtonProps } from '../../types/types';
import Check from '../Icons/Check/Check';

const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    className: currentClassName,
    type = 'button',
    view = 'primary',
    withPlus = false,
    withArrow = false,
    iconLeft = false,
    iconRight = false,
    disabled = false,
    spinner = false,
    burgerMenu = false,
    ...otherProps
  } = props;

  const className = clsx(styles.root, currentClassName, {
    [styles[`root_${view}`]]: view,
    [styles.root_withPlus]: withPlus,
    [styles.root_withArrow]: withArrow,
    [styles.root_burgerBtn]: burgerMenu,
  });

  return (
    <button type={type} disabled={disabled} {...otherProps} className={className}>
      <span className={styles.text}>
        {spinner && <div className={styles.root_spinner} />}
        {iconLeft && <Check className={clsx(styles.icon_left, styles[`icon_${view}`])} />}
        {children}
        {iconRight && <Check className={styles[`icon_${view}`]} />}
      </span>
    </button>
  );
};
export default Button;
