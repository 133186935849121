import WarningModal from './WarningModal';
import { useState } from 'react';
import WarningModalContext, { WarningModalProps } from './WarningModalContext';

const WarningModalProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const [modal, setModal] = useState<WarningModalProps | null>(null);

  return (
    <WarningModalContext.Provider value={{ modal, setModal }}>
      {modal && <WarningModal />}
      {children}
    </WarningModalContext.Provider>
  );
};
export default WarningModalProvider;
