import clsx from 'clsx';
import styles from './ProgressBar.module.scss';

const ProgressBar = ({ percent }: any) => {
  return (
    <div className={styles.progressBar}>
      <span className={styles.bar}>
        <span
          className={clsx(styles.progress, { [styles.progress_empty]: !percent })}
          style={{ width: `${percent > 100 ? 100 : percent}%` }}
        >
          {Math.round(percent)}%
        </span>
      </span>
    </div>
  );
};

export default ProgressBar;
