import React from 'react';
import locale from 'antd/locale/ru_RU';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import 'dayjs/locale/ru';

const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <AntdConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: '#FF0000',
        },
      }}
    >
      {children}
    </AntdConfigProvider>
  );
};

export default ConfigProvider;
