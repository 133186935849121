import { RoutePrivatePath } from '../../../../shared/config/routerConfig/routerConfig';
import useRoles from '../../../../shared/hooks/useRoles';
import PresentationChart from '../../../../shared/ui/Icons/PresentationChart/PresentationChart';
import styles from '../../ui/components/ItemLink/ItemLink.module.scss';
import FolderSimpleStar from '../../../../shared/ui/Icons/FolderSimpleStar/FolderSimpleStar';
import MagnifyingGlass from '../../../../shared/ui/Icons/MagnifyingGlass/MagnifyingGlass';
import Eye from '../../../../shared/ui/Icons/Eye/Eye';
export const sideBarListConfig = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isClient, isAdmin, isChannel, isManager } = useRoles();
  const sideBarList = [
    {
      id: 0,
      title: 'Каналы',
      route: RoutePrivatePath.Channels,
      canAccess: isAdmin() || isManager(),
      icon: <Eye className={styles.icon} />,
    },
    {
      id: 1,
      title: 'Клиенты',
      route: RoutePrivatePath.Customers,
      canAccess: isAdmin() || isManager(),
      icon: <MagnifyingGlass className={styles.icon} />,
    },
    {
      id: 2,
      title: 'Кампании',
      route: RoutePrivatePath.Company,
      canAccess: isAdmin() || isManager(),
      icon: <FolderSimpleStar className={styles.icon} />,
    },
    {
      id: 3,
      title: 'Кабинет клиента',
      route: RoutePrivatePath.Company,
      canAccess: isClient(),
      icon: <PresentationChart className={styles.icon} />,
    },
    {
      id: 4,
      title: 'Площадки',
      route: RoutePrivatePath.Platform,
      canAccess: isChannel(),
      icon: <PresentationChart className={styles.icon} />,
    },
  ];

  return { sideBarList };
};
