import { ModalComponent } from '../Modal/Modal';
import { useContext } from 'react';
import WarningModalContext from './WarningModalContext';
import Button from '../Button/Button';
import styles from './WarningModal.module.scss';
import Typography from '../Typography/Typography';
import { PiWarningCircleBold } from 'react-icons/pi';

const WarningModal = () => {
  const { modal, setModal } = useContext(WarningModalContext);
  return (
    <ModalComponent isModalOpen={!!modal} onClose={() => (setModal ? setModal(null) : null)}>
      <div className={styles.root}>
        <div className={styles.warning}>
          <PiWarningCircleBold size={50} color="red" />
        </div>
        <Typography className={styles.warning}>Вы уверены что хотите {modal?.message} ?</Typography>
        <div className={styles.btnsBlock}>
          <Button onClick={() => (setModal ? setModal(null) : null)}>Отмена</Button>
          <Button onClick={modal?.action}>Подтвердить</Button>
        </div>
      </div>
    </ModalComponent>
  );
};
export default WarningModal;
