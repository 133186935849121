import { Header } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import styles from './Header.module.scss';
import Typography from '../../../shared/ui/Typography/Typography';
import getLoginData from '../../../shared/helpers/getLoginData';
import Button from '../../../shared/ui/Button/Button';
import { ModalComponent } from '../../../shared/ui/Modal/Modal';
import { sideBarListConfig } from '../model/config/sideBarListConfig';
import { ItemLink } from './components/ItemLink/ItemLink';
import { useOnLogOutClick } from '../../../shared/hooks/useOnLogOutClick';
import { useAuth } from '../../../shared/hooks/useAuth';
import { Link } from 'react-router-dom';
import BurgerMenu from '../../../shared/ui/Icons/BurgerMenu/BurgerMenu';
import XClose from '../../../shared/ui/Icons/XClose/XClose';
import SignOut from '../../../shared/ui/Icons/SignOut/SignOut';
import clsx from 'clsx';
import { RoutePrivatePath } from '../../../shared/config/routerConfig/routerConfig';
import { Roles } from '../../../__generated__/graphql';
import { UserRoles } from '../../../shared/types/types';

const AppHeader: React.FC = () => {
  const [isShowModal, setShowModal] = useState(false);
  const { sideBarList } = sideBarListConfig();
  const { onLogOutClick } = useOnLogOutClick();
  const { username } = getLoginData();
  const { isAuthenticated, userRoles } = useAuth();

  const isClient = userRoles?.includes(Roles.ClientUser);

  const toggleModal = () => {
    setShowModal((prev: boolean) => !prev);
  };
  const headerModal = clsx(styles.header, { [styles.zIndexWithModal]: isShowModal });
  return (
    <Header className={headerModal}>
      <img className={styles.logo} src="/assets/logo/Logo_Red.svg" />
      {isAuthenticated && (
        <div className={styles.wrapper}>
          <div className={styles.userInfoWrapper}>
            <div className={styles.photoWrapper}>
              <img alt="" src="/assets/user/Vector.svg" />
            </div>
            <div className={styles.textWrapper}>
              <Typography component="p" className={styles.role}>
                {userRoles && UserRoles[userRoles[0] as Roles]}
              </Typography>
              <Typography component="p" className={styles.name}>
                {username}
              </Typography>
            </div>
          </div>
          {isClient && (
            <Link
              className={styles.linkAddCompany}
              to={{ pathname: RoutePrivatePath.AddCompanyToClient }}
            >
              <Button withPlus className={styles.addCompany}>
                Новая заявка
              </Button>
            </Link>
          )}
          <Button view="secondary" aria-label="burger menu" onClick={toggleModal} burgerMenu>
            {isShowModal ? (
              <XClose className={styles.iconBtn} />
            ) : (
              <BurgerMenu className={styles.iconBtn} />
            )}
          </Button>
        </div>
      )}
      <ModalComponent
        className={styles.modalWrapper}
        closeIcon={false}
        isModalOpen={isShowModal}
        onClose={toggleModal}
      >
        <section className={styles.navBarWrapper}>
          <nav className={styles.navLinks}>
            {sideBarList.map((item) => {
              const { title, id, route, icon, canAccess } = item;
              return (
                canAccess && (
                  <ItemLink
                    key={id}
                    title={title}
                    route={route}
                    icon={icon}
                    onClick={toggleModal}
                  />
                )
              );
            })}
          </nav>
          <Button
            view="transparent"
            className={styles.logOutBtn}
            onClick={() => {
              onLogOutClick();
              toggleModal();
            }}
          >
            <span className={styles.iconAndText}>
              <SignOut size={20} className={styles.icon} /> Выйти
            </span>
          </Button>
        </section>
      </ModalComponent>
    </Header>
  );
};
export default AppHeader;
