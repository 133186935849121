import { createContext, Dispatch, SetStateAction } from 'react';

export interface WarningModalProps {
  message: string;
  action: any;
}

const warningModalContext = createContext<{
  modal: WarningModalProps | null;
  setModal: Dispatch<SetStateAction<WarningModalProps | null>> | null;
}>({
  modal: null,
  setModal: null,
});
export default warningModalContext;
