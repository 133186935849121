import axios, { AxiosResponse } from 'axios';
import getLoginData from '../../../../../../shared/helpers/getLoginData';
import { env } from '../../../../../../environments/env';

export const downloadReport = async (id: string): Promise<AxiosResponse> => {
  const { token } = getLoginData();
  return await axios.post(
    `${env.REACT_APP_API_URL}/report/advCampaignStats`,
    { advCampaignId: id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    },
  );
};

export const downloadZipScreens = async (id: string): Promise<AxiosResponse> => {
  const { token } = getLoginData();
  return await axios.get(`${env.REACT_APP_API_URL}/claims/screenshots/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
};
