import React from 'react';
import '../styles/index.scss';
import { AppLayout } from '../components/Layout';
import { AppRouter } from './providers/router';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { env } from '../environments/env';
import fetch from 'cross-fetch';
import getLoginData from '../shared/helpers/getLoginData';
import NotificationsProvider from './providers/NotificationsProvider';
import WarningModalProvider from '../shared/ui/WarningModal/WarningModalProvider';

// dayjs.locale(ru);

const graphql = env.REACT_APP_GRAPHQL_URL;

const authLink = setContext((_, { headers }) => {
  const token = getLoginData() ? getLoginData()?.token : null;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const App: React.FC = () => {
  const client = new ApolloClient({
    link: from([authLink, new HttpLink({ uri: `${graphql}`, fetch })]),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Accept',
    },
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <NotificationsProvider />
      <div className="App">
        <div className="content-page">
          <AppLayout>
            <WarningModalProvider>
              <AppRouter />
            </WarningModalProvider>
          </AppLayout>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default App;
