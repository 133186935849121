import { TimePicker } from 'antd';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import './styles/TimePicker.scss';
import styles from './TimePicker.module.scss';
import React from 'react';
import clsx from 'clsx';
import Typography from '../Typography/Typography';

const TimePickerComponent: React.FC<FieldValues> = (props) => {
  const { error, className, popupClassName, errorClassName, view = 'primary', ...others } = props;
  const rootClassName = clsx(className, { [styles[`root_${view}`]]: view });
  const popupRangePickerClassName = clsx(popupClassName, { [styles[`dropdown_${view}`]]: view });
  return (
    <div className={styles.dateWrapper}>
      <TimePicker
        {...others}
        className={rootClassName}
        popupClassName={popupRangePickerClassName}
      />
      {error && (
        <Typography component="span" type="error" className={clsx(styles.error, errorClassName)}>
          {error.message}
        </Typography>
      )}
    </div>
  );
};

export default TimePickerComponent;
