import { AdvCampaignInfo, DateGraphPoint } from '../../../__generated__/graphql';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  TimeScale,
  LineController,
  Chart,
  ChartData,
  ChartDataset,
} from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import 'chartjs-plugin-zoom';
import 'chartjs-adapter-dayjs-4';
import stc from 'string-to-color';

ChartJS.register(
  ArcElement,
  LineController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  TimeScale,
  {
    id: 'lineDraw',
    afterDraw: (chart: Chart) => {
      if (chart.tooltip?.dataPoints?.length) {
        const x = chart.tooltip.dataPoints[0].element.x;
        const yAxis = chart.scales.y;
        const ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, yAxis?.top || 0);
        ctx.lineTo(x, yAxis?.bottom || 0);
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.4)';
        ctx.stroke();
        ctx.restore();
      }
    },
  },
);

const useDiagramData = (campaign: AdvCampaignInfo) => {
  const remainsCount = campaign?.placements?.reduce((acc, el) => {
    acc -= el.viewsData?.heatseekerData?.videoCampaignViewsCountSlot;
    return acc;
  }, campaign.viewCountTarget);

  const defaultData = campaign?.placements?.reduce((acc, el) => {
    acc += el.viewsData?.heatseekerData?.videoCampaignViewsCountSlot;
    return acc;
  }, 0);

  const dataOnDiagramView = campaign && {
    datasets: [
      {
        data: remainsCount > 0 ? [defaultData, remainsCount] : [defaultData],
        backgroundColor: remainsCount > 0 ? ['#1E1E1E', '#ECEDF5'] : '#1E1E1E',
        borderColor: '#1E1E1E',
        borderWidth: 1,
      },
    ],
  };

  const dataOnDiagramPercent = campaign && {
    labels: ['Мужчин', 'Женщин'],

    datasets: [
      {
        label: 'Аудитория ',
        data: [campaign.genderStats.percentMan, campaign.genderStats.percentWoman],
        backgroundColor: ['#1E1E1E', '#FF4040'],
        borderColor: '#ECEDF5',
        borderWidth: 1,
        borderRadius: 6,
      },
    ],
  };

  const sums: { dateTime: Dayjs; viewCount: number; text: string }[] =
    campaign &&
    campaign.graphStatsV2
      .reduce(
        (acc, elem) => {
          const curElemDayjs = dayjs(elem.dateTime);

          // Есть ли другое видео, с таким же временем точностью до часов
          const currentElementIndex = acc.findIndex((el) =>
            el.dateTime.isSame(curElemDayjs, 'minutes'),
          );

          // Если нет: добавляем
          if (currentElementIndex === -1) {
            return [
              ...acc,
              { dateTime: curElemDayjs, viewCount: elem.viewsIncrease, text: elem.text },
            ];
          }

          // Если да: суммируем кол-во просмотров за это время
          acc[currentElementIndex] = {
            ...acc[currentElementIndex],
            viewCount: acc[currentElementIndex].viewCount + elem.viewsIncrease,
          };
          return acc;
        },
        [] as { dateTime: Dayjs; viewCount: number; text: string }[],
      )
      .sort((a, b) => (a.dateTime.isAfter(b.dateTime) ? 1 : -1));

  const dataViewProgressOnDiagram = campaign && {
    // Slice используется для обрезки "Z" в ISO формате, из-за некорректной интерпретации часового пояса
    labels: sums.map((el) => el.dateTime.toISOString()),
    datasets: [
      {
        data: sums.map((el) => el.viewCount),
        backgroundColor: ['rgba(62,147,255,0.3)'],
        borderColor: ['rgba(0,0,0,0.5)'],
        fill: 'start',
      },
    ],
  };

  const dataViewProgressAxes = campaign && {
    datasets: [
      {
        data: sums.map((el) => el.viewCount),
      },
    ],
  };

  return {
    dataOnDiagramView,
    dataOnDiagramPercent,
    dataViewProgressAxes,
    dataViewProgressOnDiagram,
  };
};

export default useDiagramData;
