import { useAuth } from './useAuth';
import { Roles } from '../../__generated__/graphql';

const useRoles = () => {
  const { userRoles } = useAuth();

  const isAdmin = () => userRoles?.some((role) => role === Roles.AdminUser);
  const isClient = () => userRoles?.some((role) => role === Roles.ClientUser);
  const isManager = () => userRoles?.some((role) => role === Roles.ManagerUser);
  const isChannel = () => userRoles?.some((role) => role === Roles.ChannelUser);

  return { isManager, isClient, isChannel, isAdmin };
};

export default useRoles;
