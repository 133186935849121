import styles from './BlockButton.module.scss';
import { IconContext } from 'react-icons';
import { useMedia } from '../../hooks/useMedia';
import Typography from '../Typography/Typography';
import clsx from 'clsx';
import { Loader } from '../Loader/Loader';
import { Button } from 'antd';

const BlockButton = ({
  title,
  Icon,
  onClick,
  isLoading,
  disabled,
}: {
  title: string;
  Icon: any;
  size: number;
  onClick?: any;
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  const isDesktop = useMedia('(min-width: 1700px)');

  const sizeIcon = isDesktop ? 66 : 50;

  return (
    <button className={styles.layout} disabled={disabled} onClick={onClick}>
      <Typography className={styles.text}>{title}</Typography>
      <div className={styles.blockButtons}>
        {!isLoading ? (
          <div className={clsx(styles.fourthLine, styles.circles)}>
            <div className={clsx(styles.thirdLine, styles.circles)}>
              <div className={clsx(styles.secondLine, styles.circles)}>
                <div className={clsx(styles.core, styles.circles)}>
                  <IconContext.Provider
                    value={{ color: '#915eff', className: styles.nonSelectable }}
                  >
                    <Icon size={sizeIcon} color="#fff" />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </button>
  );
};

export default BlockButton;
