import React, { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, type TabsProps } from 'antd';
import { TabsTypes, ILastOpenTabs } from '../../types/types';
import './styles/Tabs.scss';
import setUserConfig from '../../helpers/userConfig/setUserConfig';
import getUserConfig from '../../helpers/userConfig/getUserConfig';

export interface ITabItem {
  key: string;
  label: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
}

interface TabsComponentProps extends TabsProps {
  className?: string;
  saveLastViewedTab?: { menu: keyof ILastOpenTabs };
  items: ITabItem[];
}

export const TabsComponent: React.FC<TabsComponentProps> = ({
  items,
  className,
  saveLastViewedTab,
  onTabClick,
  defaultActiveKey,
  ...props
}) => {
  const { state } = useLocation();
  const activeKey = state?.type;
  const [keyActive, setKeyActive] = useState<string>(activeKey);
  const onChange = (key: string): void => {
    setKeyActive(key);
  };

  const onTabClickHandler = (
    activeKey: string,
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ): void => {
    if (onTabClick) {
      onTabClick(activeKey, e);
    }

    if (saveLastViewedTab) {
      setUserConfig({
        lastOpenTabs: {
          [saveLastViewedTab?.menu]: activeKey as TabsTypes,
        },
      });
    }
  };

  const getDefaultActiveKey = (): string | undefined => {
    if (defaultActiveKey) {
      return defaultActiveKey;
    }

    if (saveLastViewedTab) {
      return getUserConfig()?.lastOpenTabs?.[saveLastViewedTab.menu] || TabsTypes.Online;
    }

    return undefined;
  };

  return (
    <Tabs
      {...props}
      onChange={onChange}
      activeKey={keyActive}
      className={className}
      items={items}
      defaultActiveKey={getDefaultActiveKey()}
      onTabClick={onTabClickHandler}
    />
  );
};
