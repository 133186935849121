import { ButtonHTMLAttributes, ReactNode, TextareaHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { FieldValues } from 'react-hook-form/dist/types/fields';
import type {
  DatePickerProps,
  MenuProps,
  SelectProps,
  InputProps as AntdInputProps,
  InputNumberProps as AntdInputNumberProps,
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';
import { type FilterValue } from 'antd/es/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import {
  AcAvailability,
  AdvCampaignPaymentStatus,
  AdvCampaignStatus,
  ClaimStatus,
  PremiumType,
} from '../../__generated__/graphql';
import * as React from 'react';

export enum DateFormats {
  DMY = 'DD.MM.YYYY',
  dashYMD = 'YYYY-MM-DD',
  request = 'YYYY-MM-DD HH:mm:ss',
}

export type PremiumTypeWithoutCustom = Exclude<PremiumType, PremiumType.Custom>;

export type ButtonView = 'primary' | 'secondary' | 'link' | 'transparent';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  view?: ButtonView;
  iconLeft?: boolean;
  iconRight?: boolean;
  withPlus?: boolean;
  withArrow?: boolean;
  spinner?: boolean;
  burgerMenu?: boolean;
}

export interface DropdownSaveProps {
  items?: MenuProps['items'];
  data?: Record<string, FilterValue | null>;
  buttonProps?: ButtonProps;
}

export interface InputProps extends AntdInputProps {
  className?: string;
  errorClassName?: string;
  image?: string;
  error?: FieldError;
  type?: string;
  magnifyingGlass?: boolean;
}

export interface InputNumberProps extends AntdInputNumberProps<number> {
  className?: string;
  errorClassName?: string;
  image?: string;
  error?: FieldError;
  magnifyingGlass?: boolean;
}

export type SelectView = 'primary' | 'secondary' | 'white' | 'transparent';

export interface ISelectProps extends SelectProps {
  view?: SelectView;
  error?: FieldError;
  errorClassName?: string;
  name?: string;
}

export type PickerView = 'primary' | 'white';
export type IRangePickerProps = RangePickerProps & {
  view?: PickerView;
  popupClassName?: string;
};

export type IDatePickerProps = DatePickerProps & {
  error?: FieldError;
  view?: PickerView;
  popupClassName?: string;
  errorClassName?: string;
  value?: Dayjs | null;
};

export type TTypographyType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'p'
  | 'error'
  | 'title'
  | 'primarySelect'
  | 'secondarySelect';

export type keysStats = 'factCpv' | 'buyCpv' | 'kpi' | 'viewCountActual';

export type ageGroupsType =
  | 'ageGroup1'
  | 'ageGroup2'
  | 'ageGroup3'
  | 'ageGroup4'
  | 'ageGroup5'
  | 'ageGroup6'
  | 'ageGroup7';

type TagComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span' | 'p' | 'strong';

export interface ITypographyProps {
  component?: TagComponent;
  type?: TTypographyType;
}

export enum TabsTypes {
  History = 'history',
  Online = 'online',
  Badges = 'badges',
  Cameras = 'cameras',
  Steams = 'steams',
  Persons = 'persons',
  Activity = 'activity',
  Video = 'video',
  Steam = 'steam',
  Doors = 'doors',
  Users = 'users',
  Settings = 'activity',
}

export interface ModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit?: (formData: any) => void;
  showTime?: boolean;
  children?: ReactNode;
  src?: string;
  destroyOnClose?: boolean;
  preview?: string;
  className?: string;
  closeIcon?: boolean;
}

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  wrapperClassName?: string;
  errorClassName?: string;
  className?: string;
  errors?: FieldError;
}

export interface CardItemHistoryProps {
  value?: string | number;
  label: string;
  src?: string;
  comment?: string;
  showModal?: () => void;
}

export interface SliderComponentProps extends FieldValues {
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  wrapperClassName?: string;
}

export interface CheckboxProps {
  className?: string;
  checked?: boolean;
  onChange: (event: CheckboxChangeEvent) => void;
  indeterminate?: boolean;
  disabled?: boolean;
  isBlueTheme?: boolean;
  isSmallTheme?: boolean;
}

export interface ILastOpenTabs {
  activityControl?: TabsTypes;
  videoControl?: TabsTypes;
  doorsControl?: TabsTypes;
  steamControl?: TabsTypes;
  reports?: TabsTypes;
  hardware?: TabsTypes;
  settings?: TabsTypes;
}

export interface IUserConfig {
  lastOpenTabs?: ILastOpenTabs;
}

export interface INotApproved {
  id: string;
  url: string;
  status: ClaimStatus;
  videoDate: string;
  slot: string;
  slotInfo: any;
}

export interface IFindChannels {
  id: string;
  url: string;
}

export interface SlotConfig {
  key: number;
  title: string;
  availability: boolean;
  money: number;
  views: number;
}

export type CompanyStatus =
  | AdvCampaignStatus
  | AdvCampaignPaymentStatus
  | AcAvailability
  | ClaimStatus;

export const UserRoles: { [key: string]: string } = {
  ADMIN_USER: 'Администратор',
  CHANNEL_USER: 'Канал',
  CLIENT_USER: 'Клиент',
  MANAGER_USER: 'Менеджер',
};
