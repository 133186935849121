import { Space, Table } from 'antd';
import clsx from 'clsx';
import styles from './Table.module.scss';
import { ITableProps } from './types/types';
import Button from '../Button/Button';
import { Loader } from '../Loader/Loader';

export const TableComponent = ({
  data,
  columns,
  handleChange,
  clearFilters,
  currentRow,
  onRowClick,
  rowId,
  rowClassName,
  className,
  loading = false,
  showClearFilters = true,
  rowSelection,
  pagination,
  scroll,
}: ITableProps<any>) => {
  const isActiveRow = (record: Record<string, any>) =>
    currentRow && record[rowId] === currentRow[rowId];

  const rowClassNames = (record: Record<string, any>) =>
    clsx(styles.row, { [styles.row_active]: isActiveRow(record) });

  const loadingIndicator = {
    indicator: <Loader />,
  };

  return (
    <div className={styles.root}>
      <Space className={styles.btnWrapper}>
        {showClearFilters && (
          <Button onClick={clearFilters} view="secondary">
            Очистить фильтры
          </Button>
        )}
      </Space>
      <Table
        rowKey={(record: { [index: string]: any }) => record[rowId]}
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        rowSelection={rowSelection && { ...rowSelection, checkStrictly: false }}
        rowClassName={rowClassName}
        className={className}
        loading={loading ? loadingIndicator : false}
        onRow={(record) => {
          return {
            onClick: () => onRowClick && onRowClick(record),
            className: rowClassNames(record),
          };
        }}
        scroll={{ ...scroll }}
        pagination={pagination}
      />
    </div>
  );
};
