import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.text}>О Recnroll</div>
        <div className={styles.text}>Вопросы и ответы</div>
        <div className={styles.text}>Политика конфиденциальности</div>
      </div>
    </footer>
  );
};

export default Footer;
