import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import pkg from '../../../../package.json';
import styles from './AppVersion.module.scss';

const AppVersion: React.FC = () => {
  return (
    <div className={styles.appVersion}>
      <BiChevronDown />
      <div className={styles.appVersionContent}>
        <p>{pkg.version}</p>
      </div>
    </div>
  );
};

export default AppVersion;
