import { useParams } from 'react-router-dom';
import {
  AdvCampaignInfo,
  useFindAdvCampaignByIdFullStatsQuery,
} from '../../../__generated__/graphql';
import { Doughnut } from 'react-chartjs-2';
import StatusBtn from '../../../shared/ui/Status/StatusBtn';
import styles from './StatsComapny.module.scss';
import { Typography } from '../../../shared/ui/Typography/Typography';
import useDiagramData from '../model/useDiagramsData';
import { Loader } from '../../../shared/ui/Loader/Loader';
import Eye from '../../../shared/ui/Icons/Eye/Eye';
import useTabControl from '../model/useTabControl';
import { TabsComponent } from '../../../shared/ui/Tabs/Tabs';
import { getSplittedNumber } from '../../../shared/helpers/getSplittedNumber';
import dayjs from 'dayjs';
import { FaPlay } from 'react-icons/fa';
import clsx from 'clsx';
import { useState } from 'react';

const StatCompanyView = () => {
  const { id } = useParams();
  const { data, loading } = useFindAdvCampaignByIdFullStatsQuery({
    variables: { id: `${id}` },
    skip: !id,
  });
  const campaign = data?.findAdvCampaignById;
  const { dataOnDiagramView } = useDiagramData(campaign as AdvCampaignInfo);
  const { items } = useTabControl(campaign as AdvCampaignInfo);
  const [controlsVis, setControll] = useState(false);

  const previewSrc = data?.findAdvCampaignById?.posterFile?.fullLink;

  const videoType = data?.findAdvCampaignById?.videoFile?.fullLink?.split('.').pop();

  const stub = '/assets/logo/Logo_Black_Box.svg';

  const percent =
    campaign &&
    (campaign?.placements.reduce(
      (partialSum, a) => partialSum + a.viewsData?.heatseekerData?.videoCampaignViewsCountSlot,
      0,
    ) /
      campaign?.viewCountTarget) *
      100;

  return (
    <div className={styles.statsWrapper}>
      {!loading && campaign ? (
        <div className={styles.root}>
          <div className={styles.infoAboutCompany}>
            <div className={styles.videoWrapper}>
              {data.findAdvCampaignById?.videoFile?.fullLink ? (
                <div className={styles.videoBlock}>
                  {!controlsVis && <FaPlay size={100} className={styles.pause} color="white" />}
                  <video
                    poster={previewSrc || 'none'}
                    style={{ background: !previewSrc ? 'var(--primary)' : `url(${previewSrc})` }}
                    className={clsx(styles.video, {
                      [styles.stub]: !data?.findAdvCampaignById.posterFile?.fullLink,
                    })}
                    controls={controlsVis}
                    onClick={() => setControll(true)}
                  >
                    <source
                      src={data.findAdvCampaignById?.videoFile?.fullLink}
                      type={`video/${videoType}`}
                    />
                  </video>
                </div>
              ) : (
                <div>
                  <img
                    src={previewSrc || stub}
                    className={clsx(styles.video, {
                      [styles.stub]: !data?.findAdvCampaignById?.posterFile?.fullLink,
                    })}
                  />
                </div>
              )}
              <Typography type="h3" component="span" className={styles.textView}>
                <div className={styles.viewText}>
                  <Eye className={styles.icon} />
                  {`${getSplittedNumber(campaign.generalStats.viewCountActual)}
                   просмотров из ${getSplittedNumber(campaign.viewCountTarget)}`}
                </div>
              </Typography>
            </div>
            <div className={styles.card}>
              <StatusBtn status={campaign?.status as any} className={styles.statusBtn} />
              <Typography className={styles.textNameComp} type="h1">
                {campaign?.name}
              </Typography>
              <div className={styles.timeCompany}>
                <div className={styles.time}>
                  <Typography type="primarySelect" className={styles.mediumText}>
                    Размещений
                  </Typography>
                  <Typography type="h2" className={styles.largeText}>
                    {campaign?.placements.length}
                  </Typography>
                </div>
                <div></div>
                <div className={styles.time}>
                  <Typography type="primarySelect" className={styles.mediumText}>
                    До:
                  </Typography>
                  <Typography type="h2" className={styles.largeText}>
                    {dayjs(campaign?.dateEnd).format('D.MM.YYYY')}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className={clsx(styles.diagram, {
                [styles.noneDiagram]: !campaign.placements?.length,
              })}
            >
              {campaign.placements?.length ? (
                <div className={styles.diаgramWrapper}>
                  <Doughnut
                    data={dataOnDiagramView}
                    options={{
                      cutout: '70%',
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                  <Typography
                    className={clsx(styles.percent, {
                      [styles.percent_low]: (percent as number) < 100,
                      [styles.percent_ok]: (percent as number) === 100,
                      [styles.percent_high]: (percent as number) > 100,
                    })}
                    component="div"
                  >
                    {Math.round(percent as number)}%
                  </Typography>
                </div>
              ) : (
                <Typography type="h2" className={styles.largeText}>
                  Размещений пока нет
                </Typography>
              )}
            </div>
          </div>
          <TabsComponent defaultActiveKey="1" items={items} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default StatCompanyView;
