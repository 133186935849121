import { IUserConfig } from '../../types/types';

export default (): IUserConfig | null => {
  const userConfigLs = localStorage.getItem('');

  if (!userConfigLs) {
    return null;
  }

  return JSON.parse(userConfigLs);
};
